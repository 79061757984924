


























































































































import {Component, Vue} from 'vue-property-decorator';


@Component

export default class Control extends Vue {

  private showPatternAConnection = false
  private showPatternBConnection = false
  private showPatternCConnection = false
  private showPatternDConnection = false
  private showPatternEConnection = false
  private showPatternFConnection = false


}


